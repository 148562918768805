@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.custom-carousel-team .p-carousel-prev,
.custom-carousel-team .p-carousel-next {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

.custom-carousel-team .p-carousel-prev {
  right: -50px;
}

.custom-carousel-team .p-carousel-next {
  left: -50px;
}

.custom-carousel-team .p-carousel-prev:hover,
.custom-carousel-team .p-carousel-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 1027px) {
  .team {
    width: 90%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .adv-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .adv-card {
    width: 200px;
    margin: 0 20px 0 20px;
  }

  .image-adv-card {
    width: 200px;
  }

  .div-image-adv-card {
    margin-bottom: -8px;
  }

  .adv-card-content {
    margin: 0;
    background-color: black;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-adv-card {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 0.9em;
    letter-spacing: 0.5px;
  }

  .social-icon {
    color: rgba(255, 255, 255, 0.54) !important;
    transition: box-shadow 0.3s, border-radius 0.3s;
  }

  .social-icon:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 20px;
    transform: translateY(-5px) scale(1.1);
  }

  .h1-team {
    color: white;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 3em;
    letter-spacing: 1.5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1026px) {
  .team {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -25% auto 0 auto;
  }

  .cards-mobile {
    display: flex;
    width: 100%;
  }

  .adv-card {
    width: 230px;
    margin: 0 auto;
  }

  .image-adv-card {
    width: 230px;
  }

  .div-image-adv-card {
    margin-bottom: -8px;
  }

  .adv-card-content {
    margin: 0;
    background-color: black;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-adv-card {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 0.9em;
    letter-spacing: 0.5px;
  }

  .social-icon {
    color: rgba(255, 255, 255, 0.54) !important;
    transition: box-shadow 0.3s, border-radius 0.3s;
  }

  .social-icon:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 20px;
    transform: translateY(-5px) scale(1.1);
  }

  .h1-team {
    color: white;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 3em;
    letter-spacing: 1.5px;
  }
}

@media screen and (max-width: 767px) {
  .team {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -25% auto 0 auto;
  }

  .cards-mobile {
    width: 100%;
  }

  .adv-card {
    width: 230px;
    margin: 0 auto;
  }

  .image-adv-card {
    width: 230px;
  }

  .div-image-adv-card {
    margin-bottom: -8px;
  }

  .adv-card-content {
    margin: 0;
    background-color: black;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-adv-card {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 0.9em;
    letter-spacing: 0.5px;
  }

  .social-icon {
    color: rgba(255, 255, 255, 0.54) !important;
    transition: box-shadow 0.3s, border-radius 0.3s;
  }

  .social-icon:hover {
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 20px;
    transform: translateY(-5px) scale(1.1);
  }

  .h1-team {
    color: white;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 3em;
    letter-spacing: 1.5px;
  }

  .custom-carousel-team .p-carousel-prev {
    right: 0;
  }
  
  .custom-carousel-team .p-carousel-next {
    left: 0;
  }
}

@media screen and (min-height: 481px) and (max-height: 900px) and (orientation: landscape) {
  .adv-cards {
    display: flex;
  }

  .team {
    margin: 0 auto;
  }
}

