@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=Montserrat:wght@300&family=PT+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/garet');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');

@media screen and (max-width: 768px){
  .iconDrawer {
    align-self: flex-start !important;
    justify-self: flex-start !important;
  }

  .logo-img {
    width: 100px;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .header-btn {
    display: none;
  }

  .toolbar {
    width: 100%;
  }

}

@media screen and (min-width: 769px) {
  .whole-header {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo-img {
    width: 130px;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .toolbar-components {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .header-btn {
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 19px;
    cursor: pointer;
    color: black;
    z-index: 1;
    font-family: "Montserrat", sans-serif;
  }
  
  .header-btn:before {
    content: "";
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .header-btn:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #39bda7;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .header-btn:hover {
    color: #ffffff;
    border: 1px solid #39bda7;
  }
  
  .header-btn:hover:before {
    top: -35%;
    background-color: #39bda7;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .header-btn:hover:after {
    top: -45%;
    background-color: #39bda7;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .menu-item {
    padding: 20px;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif !important;
    font-size: 15px !important;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
    letter-spacing: 1px !important;
  }

  .menu-item:hover {
    color: #000000;
    background-color: #ccc3;
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
  }
}