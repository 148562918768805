body, html {
  height: 100%;
  margin: 0;
  /* overflow-x: hidden; */
}

.App {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  background-image: url('./images/bg1.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center;
  position: relative;
}

.content {
  position: relative;
  z-index: 2;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
}