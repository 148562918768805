@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.custom-carousel .p-carousel-prev,
.custom-carousel .p-carousel-next {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

.custom-carousel .p-carousel-prev:hover,
.custom-carousel .p-carousel-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 1300px) {
  .services {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .services-itens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -45%;
  }

  .h1-services {
    color: white;
    font-family: "Hanken Grotesk", sans-serif;
    letter-spacing: 1.5px;
  }


  .p-carousel {
    width: 100%;
  }

  .card-carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 2px;
  }

  .img-card-services {
    width: 230px;
  }

  .div-text-card {
    background-color: #010C13;
    height: 200px;
    width: 230px;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-services-card {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 1.32em;
    text-align: center;
    letter-spacing: 1px;
  }

  .p-services-card {
    width: 200px;
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 0.8em;
    letter-spacing: 1px;
  }

  .p-carousel-indicators .p-carousel-indicator button {
    background-color: #1d1b1b;
    width: 1.5rem;
    height: 0.20rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.7rem;
    opacity: 95%;
}


.p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #a5b2bb;
  color: #495057;
}
}

@media screen and (min-width: 1301px) {
  .services {
    width: 90%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .p-carousel {
    width: 990px;
  }

  .card-carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 20px;
  }

  .img-card-services {
    width: 250px;
  }

  .div-text-card {
    background-color: #010C13;
    height: 200px;
    width: 250px;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-services-card {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 1.5em;
    text-align: center;
    letter-spacing: 1px;
  }

  .p-services-card {
    width: 200px;
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-size: 0.9em;
    letter-spacing: 1px;
  }

  .h1-services {
    color: white;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 3em;
    letter-spacing: 1.5px;
  }

  .p-carousel-indicators .p-carousel-indicator button {
    background-color: #1d1b1b;
    width: 2rem;
    height: 0.25rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    opacity: 95%;
}

.p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #a5b2bb;
  color: #495057;
}

}

@media screen and (max-width: 480px) {
  .custom-carousel .p-carousel-prev {
    right: -15px;
  }
  
  .custom-carousel .p-carousel-next {
    left: -15px;
  }
}

@media screen and (max-height: 1024px) and (orientation: landscape) {
  .services-itens {
    margin-top: 0;
  }
}