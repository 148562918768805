@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');

@media screen and (max-width: 1300px){
    .home {
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        margin-bottom: 25px;
    }

    .home-image{
        width: 155%;
        border-radius: 20%;
        object-fit: cover;
        margin-left: -32%;
        margin-top: 15%;
    }

    .home-text {
        margin-top: -10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .h1-home {
        width: 75%;
        text-align: center;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-size: 3em;
        line-height: 1em;
        letter-spacing: 1.5px;
        color: #ffffff;
    }

    .p-home {
        text-align: center;
        letter-spacing: 0.5px;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        line-height: 1.5em;
        font-size: 2em;
        width: 510px;
        color: #ffffff;
        width: 90%;
    }

    .ios .p-home {
        font-size: 14px;
    }
}

@media screen and (min-width: 1301px) {

    .home {
        margin: 0 auto;
        width: 90%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:center;
    }
    
    .home-image{
        width: 110%;
        border-radius: 20%;
        overflow: hidden;
        object-fit: cover;
        margin-left: -30px;
    }
    
    .home-text {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 40px;
        margin-top: -5%;
    }
    
    .h1-home {
        width: auto;
        text-align: start;
        font-size: 4.1em;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        line-height: 1em;
        letter-spacing: 1.5px;
    }
    
    .p-home {
        margin-top: -10px;
        text-align: start;
        letter-spacing: 0.5px;
        font-family: "Montserrat", sans-serif;
        line-height: 1.5em;
        font-size: 21px;
        width: 510px;
    }

}

@media screen and (max-width: 480px){

    .home-text {
        margin-top: -10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .h1-home {
        width: 75%;
        text-align: center;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-size: 25px;
        line-height: 28px;
        letter-spacing: 1.5px;
        color: #ffffff;
    }

    .p-home {
        text-align: center;
        letter-spacing: 0.5px;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        line-height: 1.5em;
        font-size: 14px;
        width: 510px;
        color: #ffffff;
        width: 90%;
    }

    .ios .p-home {
        font-size: 14px;
    }

}
