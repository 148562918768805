@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@media screen and (max-width: 1300px){
  .contact {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-content {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.contact-img-div {
    width: fit-content;
    width: 75%;
    height: 55%;
    margin-right: -23%;
    justify-self: flex-end;
    align-self: center;
    opacity: 40%;
}

.img-contact {
    width: 100%;
    height: 100%;
}

.contact-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 20px;
}

.ios .contact-text {
  margin-top: 30%;
}

.h1-contact {
    width: auto;
    text-align: start;
    font-family: "Hanken Grotesk", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    line-height: 1em;
    letter-spacing: 2px;
    color: #ffffff;
}

.p-contact {
    width: 100%;
    margin-top: 10px;
    text-align: start;
    color: #ffffff;
    font-size: 15px;
    line-height: 1.5em;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
}

.contact-btn {
  display: inline-block;
  padding: 12px 20px;
  margin-top: 20px;
  border-radius: 15px;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  }
  
  .contact-btn:before {
    content: "";
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .contact-btn:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #39bda7;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .contact-btn:hover {
    color: #ffffff;
    border: 1px solid #39bda7;
  }
  
  .contact-btn:hover:before {
    top: -35%;
    background-color: #39bda7;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .contact-btn:hover:after {
    top: -45%;
    background-color: #39bda7;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
}

@media screen and (min-width: 1301px) {

  .contact {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }
  
  .contact-content {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }
  
  .contact-img-div {
      width: fit-content;
      width: 35%;
      margin: 0 auto;
      margin-right: 10px;
  }
  
  .img-contact {
      width: 100%;
  }
  
  .contact-text {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 57%;
  }
  
  .h1-contact {
      width: auto;
      text-align: start;
      font-size: 3em;
      font-family: "Hanken Grotesk", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      line-height: 1em;
      letter-spacing: 2px;
      color: #ffffff;
  }
  
  .p-contact {
      width: 100%;
      margin-top: -10px;
      text-align: start;
      letter-spacing: 0.5px;
      color: #ffffff;
      font-size: 20px;
      line-height: 1.5em;
      font-family: "Montserrat", sans-serif;
      letter-spacing: 2.5px;
  }
  
  .contact-btn {
      display: inline-block;
      padding: 12px 24px;
      border: 1px solid #4f4f4f;
      border-radius: 4px;
      transition: all 0.2s ease-in;
      position: relative;
      overflow: hidden;
      font-size: 19px;
      cursor: pointer;
      color: black;
      z-index: 1;
      font-family: "Montserrat", sans-serif;
    }
    
    .contact-btn:before {
      content: "";
      left: 50%;
      transform: translateX(-50%) scaleY(1) scaleX(1.25);
      top: 100%;
      width: 140%;
      height: 180%;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      display: block;
      transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
      z-index: -1;
    }
    
    .contact-btn:after {
      content: "";
      position: absolute;
      left: 55%;
      transform: translateX(-50%) scaleY(1) scaleX(1.45);
      top: 180%;
      width: 160%;
      height: 190%;
      background-color: #39bda7;
      border-radius: 50%;
      display: block;
      transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
      z-index: -1;
    }
    
    .contact-btn:hover {
      color: #ffffff;
      border: 1px solid #39bda7;
    }
    
    .contact-btn:hover:before {
      top: -35%;
      background-color: #39bda7;
      transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }
    
    .contact-btn:hover:after {
      top: -45%;
      background-color: #39bda7;
      transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }

}


