/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Desktop */
@media screen and (min-width: 1301px) {
    .about {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: -130px;
    }
    .about-bg {
        width: 90%;
        height: 60%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 55px;
    }
    .about-btn-mobile {
        display: none;
    }
    .div-image-about {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .image-about {
        width: 70%;
        margin-left: -10%;
    }
    .about-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 70px;
        width: 50%;
    }
    .h1-about {
        width: auto;
        text-align: start;
        font-size: 3em;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        line-height: 1em;
        letter-spacing: 2px;
        color: black;
        margin-top: 10px;
    }
    .p-about {
        width: 89%;
        margin-top: -20px;
        text-align: start;
        letter-spacing: 0.5px;
        color: black;
        font-size: 18px;
        line-height: 1.2em;
        font-family: "Montserrat", sans-serif;
        letter-spacing: 2.5px;
        width: 100%;
    }
    .p-about span {
        font-weight: bold;
    }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .about {
        margin: 0 auto;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .about-bg {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('../images/about-bg-mobile3.png');
        background-repeat: no-repeat;
        background-size: 100% 90%;
        background-position: center;
        height: 100%;
    }
    .about-btn-mobile {
        margin-top: 20%;
        display: inline-block;
        padding: 28px 40px;
        border-radius: 15px;
        transition: all 0.2s ease-in;
        position: relative;
        overflow: hidden;
        font-size: 2em;
        font-weight: bold;
        cursor: pointer;
        color: black;
        z-index: 1;
        font-family: "Montserrat", sans-serif;
        background-color: #ffffff;
    }
    .about-btn-mobile:before {
        content: "";
        left: 50%;
        transform: translateX(-50%) scaleY(1) scaleX(1.25);
        top: 100%;
        width: 140%;
        height: 180%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        display: block;
        transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
        z-index: -1;
    }
    .about-btn-mobile:after {
        content: "";
        position: absolute;
        left: 55%;
        transform: translateX(-50%) scaleY(1) scaleX(1.45);
        top: 180%;
        width: 160%;
        height: 190%;
        background-color: #39bda7;
        border-radius: 50%;
        display: block;
        transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
        z-index: -1;
    }
    .about-btn-mobile:hover {
        color: #ffffff;
        border: 1px solid #39bda7;
    }
    .about-btn-mobile:hover:before {
        top: -35%;
        background-color: #39bda7;
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }
    .about-btn-mobile:hover:after {
        top: -45%;
        background-color: #39bda7;
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }
    .about-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 8%;
        margin-top: 10%;
    }
    .h1-about {
        width: auto;
        text-align: start;
        font-size: 4.5em;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        letter-spacing: 1.5px;
        color: black;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .p-about {
        margin-top: 0;
        text-align: start;
        color: black;
        font-size: 1.6em;
        font-family: "Montserrat", sans-serif;
        line-height: 1.5em;
        letter-spacing: 1.2px;
        font-weight: 600;
        width: 95%;
    }
    .p-about span {
        font-weight: 800;
    }
    .div-image-about {
        display: none;
    }
}

/* Mobile */
@media screen and (max-width: 767px) {
    .about-bg {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('../images/about-bg-mobile3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        height: 100%;
        margin: 20px auto;
    }
    .about-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 8%;
        margin-top: 10%;
    }
    .h1-about {
        width: auto;
        text-align: start;
        font-size: 2em;
        font-family: "Hanken Grotesk", sans-serif;
        font-optical-sizing: auto;
        letter-spacing: 1.5px;
        color: black;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .p-about {
        margin-top: 0;
        text-align: start;
        color: black;
        font-size: 13px;
        font-family: "Montserrat", sans-serif;
        line-height: 1.5em;
        letter-spacing: 1.2px;
        font-weight: 600;
        width: 95%;
    }
    .p-about span {
        font-weight: 800;
    }
    .div-image-about {
        display: none;
    }
    .about-btn-mobile {
        margin-top: 20%;
        display: inline-block;
        padding: 12px 24px;
        border-radius: 15px;
        transition: all 0.2s ease-in;
        position: relative;
        overflow: hidden;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
        color: black;
        z-index: 1;
        font-family: "Montserrat", sans-serif;
        background-color: #ffffff;
    }
}

@media screen and (min-height: 481px) and (max-height: 900px) and (orientation: landscape) {
    .p-about {
        font-size: 1em;
    }

    .h1-about {
        font-size: 3em;
    }

    .about-text {
        margin-top: 0px;
    }
}